$(".content-stats").each(function () {
    $(window).scroll(() => processElementScrollElement(this));
})


function isScrolledIntoView(elem) {
    let docViewTop = $(window).scrollTop();
    let docViewBottom = docViewTop + $(window).height();

    let elemTop = $(elem).offset().top;
    let elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}


function processElementScrollElement(mainClass) {
    if (isScrolledIntoView(mainClass) && !$(mainClass).hasClass("is-viewed")) {
        $(mainClass).addClass("is-viewed");
        $(mainClass).find('.stats-items__stat .number').each(function () {
            let text = $(this).text();
            let addAdditional = false;
            let additional = "";

            if ($(this).text().includes("%") || $(this).text().includes("+") || $(this).text().includes(".")) {
                text = $(this).text().replace("%", "").replace("+", "");
                addAdditional = true;
                if ($(this).text().includes("%")) {
                    additional = "%";
                }
                if ($(this).text().includes("+")) {
                    additional = "+";
                }
                if ($(this).text().includes(".")) {
                    let splitted = text.split(".");
                    text = splitted[0];
                    additional = "."+splitted[1];
                }
            }
            $(this).prop('Counter', 0).animate({
                Counter: text
            }, {
                duration: 2000,
                easing: 'swing',
                step: function (now) {
                    if (addAdditional) {
                        $(this).text(Math.ceil(now) + additional);
                    } else {
                        $(this).text(Math.ceil(now));
                    }

                }
            });
        });
    }
}
