// core version + certain modules modules:
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade, Controller } from 'swiper/core';

  // configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade, Controller]);

const Swipers = {}

if ($(window).width() > 767) {

    Swipers.testimonialsSlider = new SwiperCore('.testimonials-slider', {
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 56,
        loop: false,
        navigation: {
            nextEl: ".arrow--next",
            prevEl: ".arrow--prev",
        },
    });
}

Swipers.imageSlider = new SwiperCore('.image-slider', {
    autoplay: true,
    slidesPerView: 1,
    spaceBetween: 56,
    effect: 'fade',
    loop: false,
    navigation: {
        nextEl: ".arrow--next",
        prevEl: ".arrow--prev",
    },
});
