import {gsap} from "gsap";

let parent = $(".sub-solutions-cards");
let element = $(".sub-solutions-cards__col");
let hiddenClass = "sub-solutions-cards__col--hidden";
let trigger = $('.load-more');

function toggleItems(show, init) {
    if (parent && element) {
        let children = parent.children();
        let amount = parseInt(parent.attr("data-amount-shown"));
        children.each(function (i) {
            if ((i + 1) > amount) {
                if (show) {
                    gsap.to($(this), {
                        display: "block",
                        opacity: 1,
                        duration: 0.3,
                        delay: 0.05 * (i - amount),
                        ease: "in"
                    });
                } else if (init) {
                    gsap.to($(this), {display: "none", opacity: 0, duration: 0, delay: 0, ease: "out"});
                } else {
                    gsap.to($(this), {
                        display: "none",
                        opacity: 0,
                        duration: 0.3,
                        delay: ((children.length - amount) * 0.05) - 0.05 * i,
                        ease: "out"
                    });
                }
            }
        })
    }
}

trigger.on("click", function () {
    if (parent.hasClass("opened")) {
        toggleItems(false, false);
        parent.removeClass("opened");
        trigger.removeClass("opened");
        trigger.find("span").html(trigger.attr("data-hidden-text"));
    } else {
        toggleItems(true, false);
        parent.addClass("opened");
        trigger.addClass("opened");
        trigger.find("span").html(trigger.attr("data-shown-text"));
    }
})

toggleItems(false, true);
