// if you're using a bundler, first import:
import Headroom from "headroom.js";
// grab an element
var header = document.querySelector("header");
// construct an instance of Headroom, passing the element
var options = {
    // or you can specify offset individually for up/down scroll
    offset: {
        down: 200
    },
}
var headroom  = new Headroom(header, options);
// initialise
headroom.init();
