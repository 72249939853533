import mixitup from 'mixitup';
import mixitupMultifilter from './mixitup-multifilter.min';
import mixitupPagination from './mixitup-pagination.min';

mixitup.use(mixitupMultifilter);
mixitup.use(mixitupPagination);

jQuery(document).ready(function ($) {

    if ($('.post-wrapper').length > 0) {
        let mixer = mixitup('.post-wrapper', {
            multifilter: {
                enable: true
            },
            pagination: {
                limit: 9 // impose a limit of 8 targets per page
            },
            animation: {
                enable: false
            },
            callbacks: {
                onMixStart: function (state, futureState) {
                    $(".size-big-l").removeClass("size-big-l");
                    $(".shown-el").removeClass("shown-el");
                    $(".size-big-r").removeClass("size-big-r");

                    futureState.show.forEach((el, index) => {
                        if(index === 0){
                            $(el).addClass("size-big-l");
                        }else if((index+1) % 6 === 0){
                            $(el).addClass("size-big-r");
                        }else{
                            $(el).addClass("shown-el");
                        }
                    })
                }
            }
        });
    }

    // if ($('.testimonial-cards').length > 0) {
    //
    //     let selectFilter = document.querySelector('.select-filter');
    //
    //     let mixer = mixitup('.testimonial-cards', {
    //         multifilter: {
    //             enable: true
    //         },
    //         selectors: {
    //             target: '.mix'
    //         },
    //         pagination: {
    //             limit: 8,
    //             maintainActivePage: false,
    //             hidePageListIfSinglePage: true
    //         },
    //         templates : {
    //             pager: '<button type="button" class="${classNames}" data-page="${pageNumber}" data-mixitup-control>${pageNumber}</button>',
    //             pagerPrev: '<button type="button" class="${classNames}" data-page="prev" data-mixitup-control>Prev</button>',
    //             pagerNext: '<button type="button" class="${classNames}" data-page="next" data-mixitup-control>Next</button>'
    //         },
    //         animation: {
    //             duration: 500
    //         },
    //     });
    //
    //     selectFilter.addEventListener('change', function () {
    //         let selector = selectFilter.value;
    //         mixer.filter(selector);
    //     });
    // }

    if ($('.dealers').length > 0) {

        let selectFilter = document.querySelector('.select-filter');

        let mixer = mixitup('.dealers', {
            selectors: {
                target: '.mix'
            },
            animation: {
                duration: 500
            },
        });

        selectFilter.addEventListener('change', function () {
            let selector = selectFilter.value;
            mixer.filter(selector);
        });
    }
});
