jQuery(document).ready(function ($) {

    let $navHeight = $('.header__main').outerHeight();

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        if ($(window).width() > 1101) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $navHeight - 200
            }, 500);
        } else {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $navHeight - 40
            }, 500);
        }
    });

    if ($(window).width() > 1101) {

        $('.main-nav__item--has-child, .sub-menu').hover(
            function () {
                $("body").addClass("menu-h-active");
            },
            function () {
                $("body").removeClass("menu-h-active");
            }
        );
    }

    if ($(window).width() < 1101) {

        $('.toggle-nav').on('click', function () {
            $('.header-mobile').toggleClass('show');
            $('body').toggleClass('no-scroll');
            $('.sub-menu').removeClass('show');
            $('.lang-switch__dropdown').removeClass('show');
        });

        $('.mobile-sub-toggle').on("click", function (e) {
            $(this)
                .toggleClass('active')
                .next().toggleClass('show');
        });

        $('.sub-menu__close').on("click", function (e) {
            $('.sub-menu').removeClass('show');
        });

        $('.ham4').on('click', function () {
            $(this).toggleClass('active');
        });

        let mobileNav = $('.header-mobile');
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll >= 200) {
                mobileNav.addClass('header-mobile--fixed');
            } else {
                mobileNav.removeClass('header-mobile--fixed');
            }
        });

        $('.lang-switch__label').on('click', function () {
            $('.lang-switch__dropdown').toggleClass('show');
        });

        $('.lang-switch__close').on('click', function () {
            $('.lang-switch__dropdown').removeClass('show');
        });
    }
});
