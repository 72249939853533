var $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");

(function ($, window) {

    $(function () {

        $(".video-block").on('click', function() {
            let video_url = $(this).data('video-url');
            $.fancybox.open($({
                src  : video_url,
                'transitionIn':'elastic',
                'transitionOut':'elastic',
                'speedIn':400,
                'speedOut':200,
                'overlayShow':false,
                youtube : {
                    controls : 1,
                    showinfo : 1
                },
            }));
        });

    });
})(jQuery, window);