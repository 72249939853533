import {gsap} from "gsap";

if ($(".footer__locations--switcher")) {
    $(".footer__locations--switcher ul li").on("click", function () {
        if (!$(this).hasClass("active")) {
            let el = $("#" + $(this).attr("data-loc-el"));
            $(".footer__locations--switcher .active").removeClass("active");
            gsap.to($(".loc-active"), {
                display: "none",
                opacity: 0,
                duration: 0.3,
                ease: "out"
            });
            $(".loc-active").removeClass("loc-active");
            el.addClass("loc-active");
            $(this).addClass("active");
            gsap.to(el, {
                display: "block",
                opacity: 1,
                duration: 0.3,
                ease: "in"
            });
        }
    });
}