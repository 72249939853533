const setLanguagePopUp = (force = undefined) => {
    const popUpEl = document.querySelector('.popup[data-id="language-popup"]');
    if(!popUpEl) {
        return;
    }
    popUpEl.classList.toggle("show", force);
    document.body.classList.toggle("no-scroll", force);
}

const createAndOrAddListeners = () => {
    const langSwitch = document.querySelector('.lang-switch');
    const popUpElement = document.querySelector('.popup--language');
    const closePopUp = document.querySelector('.popup__window > .close-popup');

    langSwitch?.addEventListener("click", () => setLanguagePopUp());
    closePopUp?.addEventListener("click", () => setLanguagePopUp());
    popUpElement?.addEventListener("click", () => setLanguagePopUp());
}

window.addEventListener("load", createAndOrAddListeners);
