jQuery(document).ready(function ($) {

    $('.results-item').on('click', '.main-title', function () {
        $(this)
            .toggleClass('active')
            .next()
            .stop().slideToggle()
    });

    $('.category').on('click', '.category__header', function () {
        $(this)
            .toggleClass('active')
            .next()
            .stop().slideToggle()
    });
});
